import "./TripStyles.css";
import TripData from "./TripData";
import Trip1 from "../assets/5.jpg";
import Trip2 from "../assets/8.jpg";
import Trip3 from "../assets/6.jpg";

function Trip() {
  return (
    <div className="trip">
      <h1>Event Planning Packages</h1>
      <p>Check Out What's Included in Our Packages</p>
      <div className="tripcard">
        <TripData
          image={Trip1}
          heading="White"
          heading1="~ Investment begins at $3,500"
          text="If you are extremely hands on and 
          excited to do most of the planning on your own,
           then this package is for you! With this
            package I will be present for up to 10 
            hours on the wedding day, ensuring that 
            all details are managed, vendor arrivals 
            and deliveries occur and that your vision
             is executed for your big day."
        />
        <TripData
          image={Trip2}
          heading="Tanzanite"
          heading1="~ Investment begins at $5,500"
          text="A coordination+ package that will allow 
          you to continue to oversee all planning aspects 
          while having your planner for guidance 
          throughout! This package includes everything
           in the White Package, with a few additional
            hours on the wedding day (up to 13), 
            a detailed checklist and more"
        />
        <TripData
          image={Trip3}
          heading="Gold"
          heading1="~ Investment begins at $7,000"
          text="A partial planning package which includes
           everything from the Lavender Package,
            plus more structured meetings, 
            and check-ins with your planner.
             It will also include unlimited hours on
              the wedding day. This is perfect for
               couples who are busier or may want more
                assistance throughout the planning process."
        />
      </div>
    </div>
  );
}

export default Trip;
