import "./HomeHeroStyles.css";
import HomeLogo from "src/assets/logoresize.jpg";

function HomeHero(props) {
  return (
    <>
      <div className={props.cName}>
        <img src={props.heroImg} alt="heroImg" />
        <div className="hero-text">

          {/* <img className="taz-logo1" src={HomeLogo} /> */}

          <h1>{props.title}</h1>
          <p>{props.text}</p>
          
          <a className={props.btnClass} href={props.url}>
            
            {props.buttonText}
            
          </a>
        </div>
        
      </div>
    </>
  );
}

export default HomeHero;
