import Navbar from "../components/Navbar";
import HomeHero from "../components/HomeHero";
import Destination from "../components/Destination";
import Footer from "../components/Footer";
import Trip from "src/components/Trip";
import HomeBG from "src/assets/tanzabg2.jpg";

function Home() {
  return (
    <>
      {/* <Navbar /> */}
      <HomeHero
        cName="hero"
        heroImg={HomeBG}
        title=""
        text=""
        btnClass="hide"
        buttonText="See our prices & packages"
        url="/"
      />
      <Destination />
      <Trip />
      <Footer />
    </>
  );
}

export default Home;
